import React, { useEffect, useRef, useState, useContext } from "react";
import InvestDeposit from "../Components/InvestDeposit";
import DateTimeDisplay from "../Components/DateTimeDisplay";
import { Helmet } from "react-helmet";
import {
  BrowserRouter,
  useNavigate,
  Link,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import Nav from "../Components/Nav";
import Sidebar from "../Components/Sidebar";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import Tradingview from "../Components/Tradingview";
import { SingleTicker } from "react-ts-tradingview-widgets";
import axios from "axios";

function Dashboard() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);
  const [loading, setloading] = useState(true);
  const [dataready, setdataready] = useState(false);

  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0);

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  function cleanDate(d) {
    var date = new Date(d);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    console.log(
      "Date: " +
        date.getDate() +
        "/" +
        months[date.getMonth() + 1] +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
    );
    return `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`;
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setOpen(!open);
      planduecheck(userdetails);
      console.log(userdetails.totalearnings);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false);
        } else {
          setloggedin(false);
          setOpen(!open);
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setloggedin(true);
          setOpen(!open);
          planduecheck(doc.data());
          setdataready(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const [coinBalances, setCoinBalances] = useState({
    Bitcoin: 0,
    Tron: 0,
    Ethereum: 0,
    Doge: 0,
    "USDT Erc20": 0,
    Usdt: 0,
  });
  const planduecheck = async (info) => {
    const d1 = new Date();
    // Loop through currentSubscriptionArray
    if (info.currentSubscriptionArray.legnth != 0) {
      // && info.currentSubscription.dueDate !==null
      var Profits = 0;
      info.currentSubscriptionArray.map(async (data) => {
        // console.log(info.email);
        console.log("rumming plans", data);
        if (d1.getTime() >= data.dueDate) {
          const newBal = parseInt(info.balance) + parseInt(data.dueAmount);

          let FilteredArray = info.currentSubscriptionArray.filter((a) => {
            if (a.dueDate != data.dueDate) {
              return a;
            }
          });
          console.log("", FilteredArray);
          updateUserBalanceandSub(newBal, data.dueAmount, FilteredArray);
          console.log(data.dueDate);
        } else if (d1.getTime() < data.dueDate) {
          var today = new Date();
          var total = data.dueDate - data.dateSubscribed;
          var progress = today - data.dateSubscribed;

          console.log(Math.round((progress / total) * 100) + "%");
          // data.dueAmount-data.amount
          const currentprofit =
            ((progress / total) * 100 * (data.dueAmount - data.amount)) / 100 +
            Profits;
          console.log(currentprofit);
          Profits = currentprofit;

          console.log(data.dueDate - d1.getTime());
          const planprogress =
            ((data.dueDate - d1.getTime()) / data.dueDate) * 100;
          console.log(planprogress);
          let date = new Date(data.dueDate);
          console.log(date.toString());

          /////////////////SETING THE VALUE FOR EACH COIN INVESTMENT//////////////////
          const planProfit =
            ((progress / total) * 100 * (data.dueAmount - data.amount)) / 100;
          const coinGeckoIdMap = {
            Bitcoin: "bitcoin",
            Tron: "tron",
            Ethereum: "ethereum",
            Doge: "dogecoin",
            "USDT Erc20": "tether",
            Usdt: "tether",
          };

          const coinId = coinGeckoIdMap[data.coinUsed];
          if (!coinId) {
            console.error("Unsupported coin:", data.coinUsed);
            return;
          }

          const rate = await fetchConversionRate(coinId);
          console.log("Rate of coin", rate);
          if (rate) {
            const convertedPlanProfit = planProfit / rate;
            console.log(data.coinUsed, convertedPlanProfit);
            setCoinBalances((prevBalances) => ({
              ...prevBalances,
              [data.coinUsed]:
                prevBalances[data.coinUsed] + convertedPlanProfit,
            }));
          }
        }
      });
      console.log(coinBalances);
      console.log(Profits);
      // intrestprogress(Profits)
      setprofits(Profits);
    } else {
      console.log(info.email);
    }
  };

  const fetchConversionRate = async (coin) => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=usd`
      );
      console.log(response);
      const rate = response.data[coin]?.usd;
      return rate;
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
      return null;
    }
  };

  const updateUserBalanceandSub = (bal, addedbal, newarray) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const balanceToUse = parseFloat(userdetails.balance) + parseFloat(bal);
    const increment = firebase.firestore.FieldValue.increment(
      parseInt(addedbal)
    );
    washingtonRef
      .update({
        balance: bal,
        currentSubscription: null,
        currentSubscriptionArray: newarray,
        totalearnings: 0,
      })
      .then(function () {
        console.log("balance successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };

  const intrestprogress = (bal) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const increment = firebase.firestore.FieldValue.increment(parseInt(bal));
    washingtonRef
      .update({
        totalearning: 0,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };
  return (
    <div>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>Dashboard | {userdetails.fullname}</title>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta content="#0818A8" name="theme-color" />

      <div className="wrapper">
        <Nav />

        <div className="main-panel">
          <div className="container">
            <div className="panel-header bg-primary-gradient">
              <div className="page-inner py-5">
                <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                  <div>
                    <h2 className="text-white pb-2 fw-bold">
                      Welcome back{" "}
                      <span className="badge badge-light badge-sm">
                        {" "}
                        {userdetails.fullname}
                      </span>
                    </h2>
                    <h5 className="text-white mb-2">
                      Last Login:{" "}
                      <span className="op-5">
                        <DateTimeDisplay />
                      </span>
                    </h5>
                  </div>
                  <div className="ml-md-auto py-2 py-md-0">
                    <a
                      href="Withdrawal"
                      className="btn btn-warning btn-sm btn-round mr-2"
                    >
                      <i className="fas fa-download" /> Withdraw
                    </a>
                    <a
                      href="/deposit"
                      className="btn btn-primary btn-sm btn-round mr-2"
                    >
                      <i className="fas fa-upload" /> Deposit
                    </a>
                    {/* <a
                      href="/TransferFunds"
                      className="btn btn-white btn-border btn-sm btn-round"
                    >
                      <i className="fas fa-exchange-alt" /> Transfer
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row mt--2">
                <div className="col-md-6">
                  <div className="card  ">
                    <div className="card-body skew-shadow">
                      <div className="card-title op-5">
                        Account Summary
                        <span className="float-right clickable">
                          <i
                            data-value={0}
                            id="hideBal"
                            onclick="hideBalance(562)"
                            className="fa fa-eye"
                          />
                        </span>
                      </div>
                      <h2
                        className="py-2 fs-3 mb-0 fw-bold card-title moni"
                        id="ab"
                        data-value="$0.00"
                        style={{ fontSize: "2.1rem" }}
                      >
                        ${userdetails.Totaldeposit}
                      </h2>

                      <div className="row mb-4">
                        <div className="col-7 pr-0">
                          <h4
                            className="fw-bold mb-0 moni"
                            id="bb"
                            data-value="$0.00"
                          >
                            ${userdetails.balance}
                          </h4>
                          <div className="text-small text-uppercase fw-bold op-8">
                            Ledger Balance
                          </div>
                        </div>
                        <div className="col-5 pl-0 text-right">
                          {/* <h4 className="fw-bold mb-0 moni" id="lp" data-value="$0.00">${Math.round(profits * 10000) / 10000}</h4> */}
                          <h4
                            className="fw-bold mb-0 moni"
                            id="lp"
                            data-value="$0.00"
                          >
                            {" "}
                            $
                            {Math.round(
                              parseFloat(profits) +
                                parseFloat(userdetails.totalearnings)
                            )}{" "}
                          </h4>

                          <div className="text-small text-uppercase fw-bold op-8">
                            Live Profit
                          </div>
                          {Object.entries(coinBalances).map(
                            ([coin, balance]) => (
                              <p
                                key={coin}
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                  marginBottom: "0.5em",
                                }}
                              >
                                {coin}: {balance.toFixed(2)}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-7 pr-0">
                          <h4 className="fw-bold mb-0">53340</h4>
                          <div className="text-small text-uppercase fw-bold op-8">
                            Secret Key
                          </div>
                        </div>
                        <div className="col-5 pl-0 text-right">
                          <h4 className="fw-bold mb-0">
                            {userdetails.registrationDate}{" "}
                          </h4>
                          <div className="text-small text-uppercase fw-bold op-8">
                            Registered
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card full-height">
                    <div className="card-body">
                      <div className="card-title">
                        Popular Market
                        <span className="float-right">
                          <a
                            href="/mkt.html"
                            className="btn btn-primary btn-xs text-white"
                          >
                            All Market
                          </a>
                        </span>
                      </div>
                      <br />
                      <div className="d-flex flex-wrap justify-content-around pb-2 pt-1">
                        <div className="px-2 pb-2 pb-md-0 text-center">
                          <SingleTicker
                            colorTheme="dark"
                            width="100%"
                            symbol="BITSTAMP:BTCUSD"
                          ></SingleTicker>
                        </div>
                        <div className="px-2 pb-2 pb-md-0 text-center">
                          <SingleTicker
                            colorTheme="dark"
                            width="100%"
                            symbol="BITSTAMP:ETHUSD"
                          ></SingleTicker>
                        </div>
                        <div className="px-2 pb-2 pb-md-0 text-center">
                          <SingleTicker
                            colorTheme="dark"
                            width="100%"
                            symbol="BITSTAMP:ltcUSD"
                          ></SingleTicker>
                        </div>
                        <div className="px-2 pb-2 pb-md-0 text-center">
                          <SingleTicker
                            colorTheme="dark"
                            width="100%"
                            symbol="BITSTAMP:XRPUSD"
                          ></SingleTicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-3">
                  <div className="card p-3">
                    <div className="d-flex align-items-center">
                      <span className="stamp stamp-md bg-success mr-3">
                        <i className="fa fa-upload" />
                      </span>
                      \ Totaldeposit: 0, Totalwithdrawal: 0,
                      <div>
                        <h5 className="mb-1">
                          <b>
                            <a
                              href="/DepositHistory"
                              className="moni"
                              data-value="$0.00"
                            >
                              ${userdetails.Totaldeposit}
                            </a>
                          </b>
                        </h5>
                        <small className="text-muted">Total Deposits</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="card p-3">
                    <div className="d-flex align-items-center">
                      <span className="stamp stamp-md bg-warning mr-3">
                        <i className="fa fa-download" />
                      </span>
                      <div>
                        <h5 className="mb-1">
                          <b>
                            <a
                              href="WithdrawalHistory"
                              className="moni"
                              data-value="${userdetails.}"
                            >
                              ${userdetails.Totalwithdrawal}
                            </a>
                          </b>
                        </h5>
                        <small className="text-muted">Total Withdrawals</small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6 col-lg-3">
                  <div className="card p-3">
                    <div className="d-flex align-items-center">
                      <span className="stamp stamp-md bg-danger mr-3">
                        <i className="fa fa-gift" />
                      </span>
                      <div>
                        <h5 className="mb-1"><b><a href="transaction.html?b=1" className="moni" data-value="$0.00">$0.00</a></b></h5>
                        <small className="text-muted">Total Bonus</small>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-sm-6 col-lg-3">
                  <div className="card p-3">
                    <div className="d-flex align-items-center">
                      <span className="stamp stamp-md bg-primary mr-3">
                        <i className="fa fa-briefcase" />
                      </span>
                      <div>
                        <h5 className="mb-1">
                          <b>
                            <a
                              href="/Transactions"
                              className="moni"
                              data-value="$0.00"
                            >
                              {userdetails.Totaldeposit +
                                userdetails.Totalwithdrawal}
                            </a>
                          </b>
                        </h5>
                        <small className="text-muted">Total Transactions</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-card-no-pd">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">History</h4>
                        <div className="card-tools">
                          <a
                            href="/Transactions"
                            className="btn btn-primary btn-xs"
                          >
                            View All Transactions
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="table-responsive table-hover table-sales">
                            <table className="table table-striped">
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <h3 className="text-center card-title">
                            Invite Your Friends
                          </h3>
                          <p className="text-center">
                            <small className="text-muted">
                              Earn more when you refer your friends to invest
                              with us. The reward on our referral program is
                              dependent on the deposit plans.
                            </small>
                          </p>
                          <div className="row">
                            <div className="col-6 mb-4">
                              <a
                                href="mailto:?subject=Invitation&body=Hello+dear%2C+I+am+happy+to+invite+you+to+join+Parexco%2C+a+reliable+investment+website+that+helps+you+to+trade+and+return+your+profit.+Use+my+link+to+register+http%3A%2F%2Fparexco.com%2Fuser%2Fsignup.html%3Fref%3Dgett"
                                className="btn btn-warning btn-block"
                              >
                                <i className="fa fa-envelope" />
                                Email
                              </a>
                            </div>
                            <div className="col-6 mb-4">
                              <a
                                href="https://facebook.com/sharer/sharer.html?u=http%3A%2F%2Fparexco.com%2Fuser%2Fsignup.html%3Fref%3Dgett"
                                className="btn btn-primary btn-block"
                              >
                                <i className="fab fa-facebook" /> Facebook
                              </a>
                            </div>
                            <div className="col-6 ">
                              <a
                                href="https://wa.me/?text=Hello+dear%2C+I+am+happy+to+invite+you+to+join+Parexco%2C+a+reliable+investment+website+that+helps+you+to+trade+and+return+your+profit.+Use+my+link+to+register+http%3A%2F%2Fparexco.com%2Fuser%2Fsignup.html%3Fref%3Dgett"
                                className="btn btn-success btn-block"
                              >
                                <i className="fab fa-whatsapp" /> Whatsapp
                              </a>
                            </div>
                            <div className="col-6 ">
                              <a
                                href="https://t.me/share/?text=Hello+dear%2C+I+am+happy+to+invite+you+to+join+Parexco%2C+a+reliable+investment+website+that+helps+you+to+trade+and+return+your+profit.+Use+my+link+to+register+http%3A%2F%2Fparexco.com%2Fuser%2Fsignup.html%3Fref%3Dgett"
                                className="btn btn-outline-primary btn-block"
                              >
                                <i className="fa fa-paper-plane" /> Telegram
                              </a>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-12">
                              <form className="mt-4">
                                <div className="mb-3">
                                  <input type="text" id="ref_cop" className="form-control" defaultValue="parexco.com/user/signup.html?ref=gett" />
                                </div>
                                <button id="ref_btn" type="button" onclick="copy_ref('ref_cop','#ref_btn');" className="btn btn-primary btn-block"><i className="fa fa-copy" />
                                  Copy Referral Link</button>
                              </form>
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-12">
                              <form className="mt-4">
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    id="ref_cop"
                                    className="form-control"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        `https://www.Streamlineglobe.com/user/signup.html?ref=${userdetails.fullname}`
                                      );
                                    }}
                                    defaultValue="https://www.Streamlineglobe.com/user/signup.html?ref=ositasteveai"
                                  />
                                </div>
                                <button
                                  id="ref_btn"
                                  type="button"
                                  onclick="copy_ref('ref_cop','#ref_btn');"
                                  className="btn btn-primary btn-block"
                                >
                                  <i className="fa fa-copy" /> Copy Referral
                                  Link
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tradingview />

              <h4 className="page-title text-primary">
                Hot Pricing Plans
                <span className="float-right">
                  <a
                    href="/Myplans"
                    className="btn btn-primary btn-border btn-xs text-white"
                  >
                    View All Plans
                  </a>
                </span>
              </h4>

              <InvestDeposit />
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="language langBox" style={{}}>
                <img
                  className="img-fluid mb-0"
                  src="../images/icons/lang-b.png"
                  alt=""
                  style={{ height: "50px", width: "auto" }}
                />
                <div id="google_translate_element" />
              </div>
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="support.html">
                      Contact Support
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Refferals">
                      My Referral
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright ml-auto">
                2024made with <a href="home.html">Streamlineglobe</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="watsPanel">
        <a href="#langDiv">
          <img
            className="img-fluid mb-0"
            src="../images/icons/lang.png"
            alt=""
          />
        </a>
        <hr className="m-2" />
        <a href="https://wa.me/+447300322960" id="heheh">
          <img
            className="img-fluid"
            src="../images/icons/whatsapp.png"
            alt=""
          />
        </a>
        <a href="https://wa.me/+447300322960">
          <img
            className="img-fluid"
            src="../images/icons/whatsapp-2.png"
            alt=""
          />
        </a>
      </div>
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* Moment JS */}
      {/* Chart JS */}
      {/* jQuery Sparkline */}
      {/* Chart Circle */}
      {/* Datatables */}
      {/* Bootstrap Notify */}
      {/* Bootstrap Toggle */}
      {/* jQuery Vector Maps */}
      {/* Google Maps Plugin */}
      {/* Dropzone */}
      {/* Fullcalendar */}
      {/* DateTimePicker */}
      {/* Bootstrap Tagsinput */}
      {/* Bootstrap Wizard */}
      {/* jQuery Validation */}
      {/* Summernote */}
      {/* Select2 */}
      {/* Sweet Alert */}
      {/* Owl Carousel */}
      {/* Magnific Popup */}
      {/* Atlantis JS */}
      {/* Code provided by Google */}
    </div>
  );
}

export default Dashboard;
