import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

function Nav() {


  //START AUTHOMATIC LOGOUT AFTER 10 MINUTES 600000 MILISECONDS
  let timer;
  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
      timer = setTimeout(() => {
          // clears any pending timer.
          resetTimer();
          // Listener clean up. Removes the existing event listener from the window
          Object.values(events).forEach((item) => {
              window.removeEventListener(item, resetTimer);
          });
          // logs out user
          //600000 == 10 minutes
          logoutAction();
      }, 300000); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
      if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  // useEffect(() => {
  //     Object.values(events).forEach((item) => {
  //         window.addEventListener(item, () => {
  //             resetTimer();
  //             handleLogoutTimer();
  //         });
  //     });
  // }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
      localStorage.clear();
      window.location.pathname = "/home.html";
  };
  //END AUTHOMATIC LOGOUT AFTER 10 MINUTES 600000 MILISECONDS






  const navigate = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };



  return (
    <>
      <div className="main-header">
        {/* Logo Header */}
        <div className="logo-header" data-background-color="blue">
          <a href="home.html" className="logo">
            <img alt="" src="../util/logo/logo-white.png" style={{ height: '30px', width: 'auto' }} className="navbar-brand" />
          </a>
          <button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <i className="fas fa-bars" />
            </span>
          </button>
          <button className="topbar-toggler more"><i className="fas fa-ellipsis-v" /></button>
          <div className="nav-toggle">
            <button className="btn btn-toggle toggle-sidebar">
              <i className="fas fa-bars" />
            </button>
          </div>
        </div>
        {/* End Logo Header */}
        {/* Navbar Header */}
        <nav className="navbar navbar-header navbar-expand-lg" data-background-color="blue2">
          <div className="container-fluid">
            <nav className="navbar navbar-line navbar-header-left navbar-expand-lg p-0  d-none d-lg-flex">
              <ul className="navbar-nav page-navigation page-navigation-info">
                <li className="nav-item">
                  <a className="nav-link" href="/deposit">
                    <i className="fa fa-upload" /> Deposit
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Withdrawal">
                    <i className="fa fa-download" /> Withdraw
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="wallet.html">
                    <i className="fa fa-wallet" /> Wallet
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="/Transactions">
                    <i className="fa fa-download" /> History
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Myplans">
                    <i className="fa fa-layer-group" /> Plans
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="support.html">
                    <i className="fa fa-comment" /> Support
                  </a>
                </li>
              </ul>
            </nav>
            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
              <li className="nav-item dropdown hidden-caret">
                <a className="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-bell" />
                </a>
                <ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
                  <li>
                    <div className="dropdown-title">You have 0 new notification</div>
                  </li>
                  <li>
                    <div className="scroll-wrapper notif-scroll scrollbar-outer" style={{ position: 'relative' }}>
                      <div className="notif-scroll scrollbar-outer scroll-content" style={{ height: 'auto', marginBottom: '0px', marginRight: '0px', maxHeight: '0px' }}>
                        <div className="notif-center">
                        </div>
                      </div>
                      <div className="scroll-element scroll-x" style={{}}>
                        <div className="scroll-element_outer">
                          <div className="scroll-element_size" />
                          <div className="scroll-element_track" />
                          <div className="scroll-bar ui-draggable ui-draggable-handle" />
                        </div>
                      </div>
                      <div className="scroll-element scroll-y" style={{}}>
                        <div className="scroll-element_outer">
                          <div className="scroll-element_size" />
                          <div className="scroll-element_track" />
                          <div className="scroll-bar ui-draggable ui-draggable-handle" />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a className="see-all" href="notification.html">See all notifications<i className="fa fa-angle-right" /> </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown hidden-caret">
                <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                  <i className="fa fa-th" />
                </a>
                <div className="dropdown-menu quick-actions quick-actions-primary animated fadeIn">
                  <div className="quick-actions-header">
                    <span className="title mb-1">Shortcuts</span>
                  </div>
                  <div className="scroll-wrapper quick-actions-scroll scrollbar-outer" style={{ position: 'relative' }}>
                    <div className="quick-actions-scroll scrollbar-outer scroll-content" style={{ height: 'auto', marginBottom: '0px', marginRight: '0px', maxHeight: '0px' }}>
                      <div className="quick-actions-items">
                        <div className="row m-0">
                          <a className="col-6 col-md-4 p-0" href="Profile">
                            <div className="quick-actions-item">
                              <div className="avatar-item bg-primary rounded-circle">
                                <i className="far fa-user" />
                              </div>
                              <span className="text">My Profile</span>
                            </div>
                          </a>
                          {/* <a className="col-6 col-md-4 p-0" href="wallet.html">
                            <div className="quick-actions-item">
                              <div className="avatar-item bg-warning rounded-circle">
                                <i className="fas fa-wallet" />
                              </div>
                              <span className="text">My Wallets</span>
                            </div>
                          </a> */}
                          {/* <a className="col-6 col-md-4 p-0" href="transaction.html?b=1">
                            <div className="quick-actions-item">
                              <div className="avatar-item bg-danger rounded-circle">
                                <i className="fas fa-gift" />
                              </div>
                              <span className="text">My Bonus</span>
                            </div>
                          </a> */}
                          <a className="col-6 col-md-4 p-0" href="/Refferals">
                            <div className="quick-actions-item">
                              <div className="avatar-item bg-info rounded-circle">
                                <i className="fas fa-users" />
                              </div>
                              <span className="text">My Referral</span>
                            </div>
                          </a>
                          <a className="col-6 col-md-4 p-0" href="/deposit">
                            <div className="quick-actions-item">
                              <div className="avatar-item bg-success rounded-circle">
                                <i className="fas fa-upload" />
                              </div>
                              <span className="text">Make Deposit</span>
                            </div>
                          </a>
                          <a className="col-6 col-md-4 p-0" onClick={logout}>
                            <div className="quick-actions-item">
                              <div className="avatar-item bg-secondary rounded-circle">
                                <i className="fas fa-sign-out-alt" />
                              </div>
                              <span className="text">Log Out</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="scroll-element scroll-x" style={{}}>
                      <div className="scroll-element_outer">
                        <div className="scroll-element_size" />
                        <div className="scroll-element_track" />
                        <div className="scroll-bar ui-draggable ui-draggable-handle" />
                      </div>
                    </div>
                    <div className="scroll-element scroll-y" style={{}}>
                      <div className="scroll-element_outer">
                        <div className="scroll-element_size" />
                        <div className="scroll-element_track" />
                        <div className="scroll-bar ui-draggable ui-draggable-handle" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item" style={{ backgroundColor: 'transparent' }}>
                <a style={{ backgroundColor: 'transparent' }} href="javascript:void(0);" id="themeKey" data-value={0} onclick="toggleTheme(562)" className="nav-link ">
                  <i className="fa fa-moon" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
        {/* End Navbar */}
      </div>
      <div className="sidebar sidebar-style-2" id="themeBar" data-background-color="white">
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <div className="user">
              <div className="avatar-sm float-left mr-2">
                <img src="images/img1.png" alt="..." className="avatar-img rounded-circle" />
              </div>
              <div className="info">
                <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                  <span>
                    {userdetails.fullname} <span className="user-level"> {userdetails.fullname}</span>
                    <span className="caret" />
                  </span>
                </a>
                <div className="clearfix" />
                <div className="collapse in" id="collapseExample">
                  <ul className="nav">
                    <li>
                      <a href="/Profile">
                        <span className="link-collapse">My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Refferals">
                        <span className="link-collapse">My Referral</span>
                      </a>
                    </li>
                    {/* <li>
                      <a href="wallet.html">
                        <span className="link-collapse">My Wallet</span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <ul className="nav nav-primary">
              <li className="nav-item active">
                <a href="/dashboards">
                  <i className="fas fa-home" />
                  <p>Dashboard</p>
                </a>
              </li>
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">Quick Links</h4>
              </li>
              <li className="nav-item">
                <a href="/deposit">
                  <i className="fas fa-upload" />
                  <p>Deposit</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/Withdrawal">
                  <i className="fas fa-download" />
                  <p>Withdraw</p>
                </a>
              </li>
              {/* <li className="nav-item ">
                <a href="/TransferFunds">
                  <i className="fas fa-exchange-alt" />
                  <p>Transfer</p>
                </a>
              </li> */}
              {/* <li className="nav-item ">
                <a href="wallet.html">
                  <i className="fas fa-wallet" />
                  <p>My Wallet</p>
                </a>
              </li> */}
              <li className="nav-item ">
                <a href="/Myplans">
                  <i className="fas fa-layer-group" />
                  <p>Pricing Plans</p>
                </a>
              </li>
              {/* <li className="nav-item ">
                <a href="transaction.html?b=1">
                  <i className="fas fa-gift" />
                  <p>Bonus</p>
                </a>
              </li> */}
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">History</h4>
              </li>
              <li className="nav-item ">
                <a href="/Transactions">
                  <i className="fas fa-file" />
                  <p>All Transactions</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/DepositHistory">
                  <i className="fas fa-file" />
                  <p>Deposit History</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/WithdrawalHistory">
                  <i className="fas fa-file" />
                  <p>Withdrawal History</p>
                </a>
              </li>
              {/* <li className="nav-item ">
                <a href="/TransferHistory">
                  <i className="fas fa-file" />
                  <p>Transfer History</p>
                </a>
              </li> */}
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">My Account</h4>
              </li>
              <li className="nav-item ">
                <a href="/Profile">
                  <i className="fas fa-user" />
                  <p>My Profile</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/Refferals">
                  <i className="fas fa-users" />
                  <p>My Referral</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="notification.html">
                  <i className="fas fa-bell" />
                  <p>Notifications</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/contact.html">
                  <i className="fas fa-comment" />
                  <p>Contact Support</p>
                </a>
              </li>
              <li className="nav-item ">
                <a onClick={logout}>
                  <i className="fas fa-sign-out-alt" />
                  <p>Logout</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nav