import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import InvestDeposit from '../Components/InvestDeposit';
import Tradingview from '../Components/Tradingview';

function Refferals() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [dataready, setdataready] = useState(false)


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
          setdataready(true)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  return (
    <div>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>My Referral History | {userdetails.fullname} </title>
      <div className="wrapper">
        <Nav />
        <div id="loader" className="text-center card" style={{ position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855' }}>
          <div className="text-info text-center">
            <img className style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px' }} src="../util/logo/logo-icon.png" height="auto" width="60px" />
          </div>
        </div>
        <div className="main-panel">
          <div className="container">
            <div className="bg-primary2 pt-4 pb-5" style={{ marginBottom: '-80px' }}>
              <div className="container text-white py-2">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h2 className="mt-1">Referral</h2>
                  </div>
                  <div className="ml-auto">
                    <a href="Withdrawal" className="btn btn-warning btn-round btn-xs">Withdraw</a>
                    
                    <a href="/Transactions" className="btn btn-dark btn-round btn-xs">History</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card">
                    <div className="card-body px-0">
                      <div className="col-md-12 mt-3">
                        <h3 className="text-center card-title">Invite Your Friends</h3>
                        <p className="text-center"><small className="text-muted">Earn more when you refer your friends to invest with us. The reward on our referral program is dependent on the deposit plans.</small></p>
                        <div className="row">
                          <div className="col-6 col-md-3 mb-4">
                            <a href="mailto:?subject=Invitation&body=Hello+dear%2C+I+am+happy+to+invite+you+to+join+Streamlineglobe%2C+a+reliable+investment+website+that+helps+you+to+trade+and+return+your+profit.+Use+my+link+to+register+http%3A%2F%2FStreamlineglobe.com%2Fuser%2Fsignup.html%3Fref%3Diu75gw5ga" className="btn btn-warning btn-block"><i className="fa fa-envelope" /> Email</a>
                          </div>
                          <div className="col-6 col-md-3 mb-4">
                            <a href="https://facebook.com/sharer/sharer.html?u=http%3A%2F%2FStreamlineglobe.com%2Fuser%2Fsignup.html%3Fref%3Diu75gw5ga" className="btn btn-primary btn-block"><i className="fab fa-facebook" /> Facebook</a>
                          </div>
                          <div className="col-6 col-md-3 ">
                            <a href="https://wa.me/?text=Hello+dear%2C+I+am+happy+to+invite+you+to+join+Streamlineglobe%2C+a+reliable+investment+website+that+helps+you+to+trade+and+return+your+profit.+Use+my+link+to+register+http%3A%2F%2FStreamlineglobe.com%2Fuser%2Fsignup.html%3Fref%3Diu75gw5ga" className="btn btn-success btn-block"><i className="fab fa-whatsapp" /> Whatsapp</a>
                          </div>
                          <div className="col-6 col-md-3 ">
                            <a href="https://t.me/share/?text=Hello+dear%2C+I+am+happy+to+invite+you+to+join+Streamlineglobe%2C+a+reliable+investment+website+that+helps+you+to+trade+and+return+your+profit.+Use+my+link+to+register+http%3A%2F%2FStreamlineglobe.com%2Fuser%2Fsignup.html%3Fref%3Diu75gw5ga" className="btn btn-outline-primary btn-block"><i className="fa fa-paper-plane" /> Telegram</a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <form className="mt-4">
                              <div className="mb-3">
                                <input type="text" id="ref_cop" className="form-control" onClick={()=>{navigator.clipboard.writeText(`https://www.Streamlineglobe.com/user/signup.html?ref=${userdetails.fullname}`)}} defaultValue="https://www.Streamlineglobe.com/user/signup.html?ref=iu75gw5ga" />
                              </div>
                              <button id="ref_btn" type="button" onclick="copy_ref('ref_cop','#ref_btn');" className="btn btn-primary btn-block"><i className="fa fa-copy" /> Copy Referral Link</button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">Referral History</h4>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="table-responsive table-hover table-sales">
                        <table id="order-listing" className="table table-striped">
                          <thead>
                          </thead>
                          <tbody>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tradingview />
              <h4 className="page-title text-primary">Hot Pricing Plans<span className="float-right">
                <a href="/Myplans" className="btn btn-primary btn-border btn-xs text-white">View All Plans</a>
              </span></h4>
              <InvestDeposit />
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="support.html">
                      Contact Support
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Refferals">
                      My Referral
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright ml-auto">
                2024made with <a href="home.html">Streamlineglobe</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="watsPanel">
        <a href="#langDiv">
          <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
        </a>
        <hr className="m-2" />
        <a href="https://wa.me/+447300322960" id="heheh">
          <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
        </a>
        <a href="https://wa.me/+447300322960">
          <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
        </a>
      </div>
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* Moment JS */}
      {/* Chart JS */}
      {/* jQuery Sparkline */}
      {/* Chart Circle */}
      {/* Datatables */}
      {/* Bootstrap Notify */}
      {/* Bootstrap Toggle */}
      {/* jQuery Vector Maps */}
      {/* Google Maps Plugin */}
      {/* Dropzone */}
      {/* Fullcalendar */}
      {/* DateTimePicker */}
      {/* Bootstrap Tagsinput */}
      {/* Bootstrap Wizard */}
      {/* jQuery Validation */}
      {/* Summernote */}
      {/* Select2 */}
      {/* Sweet Alert */}
      {/* Owl Carousel */}
      {/* Magnific Popup */}
      {/* Atlantis JS */}
      {/* Code provided by Google */}
      {/* End custom js for this page */}
    </div>
  )
}

export default Refferals