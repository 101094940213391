import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyA8N-Krb8TtlQMIlWPbDeLb1R8Vg_FE5bg",
  authDomain: "streamlineglobe-74b13.firebaseapp.com",
  databaseURL: "https://streamlineglobe-74b13-default-rtdb.firebaseio.com",
  projectId: "streamlineglobe-74b13",
  storageBucket: "streamlineglobe-74b13.appspot.com",
  messagingSenderId: "77617002688",
  appId: "1:77617002688:web:bd02daa8aaa95b156c5525",
  measurementId: "G-83KMC9F8NP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()